import React from "react";
import './team.css';

export default class MouseFollower extends React.Component {
    state = {
        left: 0,
        top: 0
    };
    componentDidMount = () => {
        document.addEventListener("mousemove", this.updatePosition);
    };
    componentWillUnmount = () => {
        document.removeEventListener("mousemove", this.updatePosition);
    };
    updatePosition = event => {
        this.setState({
            left: event.clientX,
            top: event.clientY
        });
    };
    render() {
        const styles = {
            left: this.state.left,
            top: this.state.top,
        };
        return <div style={styles} className="followTheMouse"/>;
    }
}