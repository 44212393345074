import React, { useState } from 'react';
import './team.css';
import cardLogo from '../assets/cardLogo.png';
import taleri from '../assets/Taieri.svg';
import OurTeam from './OurTeam';
import Contact from './Contact';
import Knowmore from './Knowmore';
import MouseFollower from './MouseFollower';


export default function Team() {
    const [TeamView, setTeamView] = useState(false)
    const [ContactView, setContactView] = useState(false)
    const [AboutView, setAboutView] = useState(false)

    const [CardOneStyle, setCardOneStyle] = useState({
        transform: "scale(1)",
        backgroundColor: "#121d30"
    });
    const [CardTwoStyle, setCardTwoStyle] = useState({
        transform: "scale(1)",
        backgroundColor: "#121d30"
    });
    const [CardThreeStyle, setCardThreeStyle] = useState({
        transform: "scale(1)",
        backgroundColor: "#121d30"
    });




    return (
        <section className='panel'>

            <div id='Team'>
                <div className="team">
                    {/* card #3 */}
                    <div className="growAnimation1"
                        style={CardOneStyle}
                    ></div>
                    <div className="about_company_card" data-aos = "fade-up" data-aos-duration = "600"
                        onClick={() => {
                            setCardOneStyle({
                                transform: "scale(2400)",
                                backgroundColor: "var(--backgroundColor)"
                            })
                            setTimeout(() => {
                                setAboutView(true);
                            }, 500);
                        }}
                    >
                        <div className="card_decoration">
                            <img src={taleri} alt="" />
                        </div>
                        <div className='hiddenDiv'>
                            <div className="illustration_Container">
                                <img src={cardLogo} alt="" />
                            </div>
                            <p className='cardHeadingLine'>Want to know more about our company?</p>
                        </div>
                        <div className="access_controls">
                            <p className='forwardTextStyling'>Know more</p>
                            <div className="forwardButton">
                                <lord-icon
                                    src="https://cdn.lordicon.com/iifryyua.json"
                                    trigger="hover"
                                    colors="primary:#ffffff"
                                    style={{ width: "100%" }}>
                                </lord-icon>
                            </div>
                        </div>
                    </div>

                    {
                        AboutView === true ? <Knowmore setCardOneStyle={setCardOneStyle} setAboutView={setAboutView} /> : null
                    }






                    {/* card #2 */}
                    <div className="growAnimation2"
                        style={CardTwoStyle}
                    ></div>
                    <div className="about_company_card" data-aos = "fade-up" data-aos-duration = "800"
                        onClick={() => {
                            setCardTwoStyle({
                                transform: "scale(2400)",
                                backgroundColor: "var(--backgroundColor)"
                            })
                            setTimeout(() => {
                                setContactView(true);
                            }, 500);
                        }}
                    >
                        <div className="card_decoration">
                            <img src={taleri} alt="" />
                        </div>
                        <div className='hiddenDiv'>
                            <div className="illustration_Container">
                                <lord-icon
                                    src="https://cdn.lordicon.com/rhvddzym.json"
                                    trigger="hover"
                                    colors="primary:#ffffff,secondary:#08a88a"
                                    style={{ width: "12vh", height: "12vh" }}>
                                </lord-icon>
                            </div>
                            <p className='cardHeadingLine'>Want to Connect?  Send us your mail here</p>
                        </div>
                        <div className="access_controls">
                            <p className='forwardTextStyling'>Contact us</p>
                            <div className="forwardButton">
                                <lord-icon
                                    src="https://cdn.lordicon.com/iifryyua.json"
                                    trigger="hover"
                                    colors="primary:#ffffff"
                                    style={{ width: "100%" }}>
                                </lord-icon>
                            </div>
                        </div>
                    </div>
                    {
                        ContactView === true ? <Contact setContactView={setContactView} setCardTwoStyle={setCardTwoStyle} /> : null
                    }




                    {/* card #3 */}
                    <div className="growAnimation3"
                        style={CardThreeStyle}
                    ></div>
                    <div className="about_company_card" data-aos = "fade-up" data-aos-duration = "1000"
                        onClick={() => {
                            setCardThreeStyle({
                                transform: "scale(2400)",
                                backgroundColor: "var(--backgroundColor)"
                            })
                            setTimeout(() => {
                                setTeamView(true);
                            }, 200);
                        }}
                    >
                        <div className="card_decoration">
                            <img src={taleri} alt="" />
                        </div>
                        <div className='hiddenDiv'>
                            <div className="illustration_Container">
                                <lord-icon
                                    src="https://cdn.lordicon.com/uukerzzv.json"
                                    trigger="hover"
                                    colors="primary:#ffffff,secondary:#121d30"
                                    style={{ width: "12vh", height: "12vh", overflow: "visible" }}>
                                </lord-icon>
                            </div>
                            <p className='cardHeadingLine'>Want to meet with our team?</p>
                        </div>
                        <div className="access_controls">
                            <p className='forwardTextStyling'>Our team</p>
                            <div className="forwardButton">
                                <lord-icon
                                    src="https://cdn.lordicon.com/iifryyua.json"
                                    trigger="hover"
                                    colors="primary:#ffffff"
                                    style={{ width: "100%" }}>
                                </lord-icon>
                            </div>
                        </div>
                    </div>
                    {
                        TeamView === true ? <OurTeam setCardThreeStyle={setCardThreeStyle} setTeamView={setTeamView} /> : null
                    }


                </div>
            </div>


            {/* <MouseFollower /> */}
        </section>
    )
}
