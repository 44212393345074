import React , { useEffect } from 'react'
import styles from './decon.module.css';
//  import gsap from "gsap";
//  import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function Decon() {

    //  useEffect(() => {
    //      gsap.registerPlugin(ScrollTrigger);
    //      gsap.to("#products", {
    //          scrollTrigger: {
    //              trigger: "#products",
    //              start: "top top",
    //              end: "bottom top",
    //                 markers: { fontSize: "2em" },
    //              pin: true,
    //              scrub : 1
    //          },
    //          duration : 1
    //      })
    //      gsap.to("#productyuvaan", {
    //          scrollTrigger: {
    //              trigger: "#productyuvaan",
    //              start: "top top",
    //              end: "bottom top",
    //              markers: { fontSize: "2em" },
    //              pin: true,
    //              scrub : 1
    //          },
    //          duration : 1,
    //          // y : -100
    //      })
    //      gsap.to("#productgurucamp", {
    //          scrollTrigger: {
    //              trigger: "#productgurucamp",
    //              start: "top top",
    //              end: "bottom top",
    //             markers: { fontSize: "2em" },
    //              pin: true,
    //              scrub : 1
    //          },
    //          duration : 1,
    //          // y : -100
    //      })
    //  }, [])



    return (
        <section className='panel'>
            <div className={styles.decon} id='products'>
                <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/deconwaves_D5Tbplooe.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648666104508" alt="waves" className={styles.waves} />
                <div className="detailWrapper">
                    <div className="upperDetails">
                        <div className="logoWrapper">
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/deconLogo_Vs8RkMa29.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648664097592" alt="" />
                        </div>
                        <div className="p_name">
                            <h1 className="heading40px">Decon</h1>
                            <p className='universal-paraghraph'>Devices empowering the Nation with smart infrastructures
                            </p>
                        </div>
                    </div>
                    <div className={styles.descriptionWrapper} data-aos = "fade-right">
                        <p className='universal-paraghraph'>
                        <b style={{ color: 'var(--deconColor)' }}>Decon</b> is a series of highly robust and energy-efficient devices incorporating the seamless power of IoT, AI, and Cloud. The devices empower smart cities and smart industries in becoming cleaner, greener, and sustainable.</p>
                        <a href="https://decon-vysion.netlify.app/" target="_blank" rel="noreferrer"><button className='universal-button decon'>Visit Website</button></a>
                    </div>
                </div>
                <div className={styles.animationWrapper} data-aos = "fade-left">
                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/deconDevice_Ppw3dTj1x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648833949039" alt="decon" className={styles.deconDevice} />
                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/deconFeatures_cEBLjmFp2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648833948909" alt="deconfeatures" className={styles.deconFeatures} />
                </div>
            </div>
        </section>
    )
}
