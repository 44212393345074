import React from 'react'
import './knowmore.css'
import taleri from '../assets/Taieri.svg';

export default function Knowmore(props) {
  return (
    <div className="knowMoreWrapper">
      <button className="closeContactButton" onClick={() => {
        props.setAboutView(false)
        props.setCardOneStyle({
          transform: "scale(1)",
          backgroundColor: "#121d30",
          display: "block"
        })
      }}
      >
        <lord-icon
          src="https://cdn.lordicon.com/mecwbjnp.json"
          trigger="hover"
          colors="primary:#061021,secondary:#ffffff"
          style={{ width: "100px", height: "100px" }}>
        </lord-icon>
      </button>

      <div className="AboutCompany" data-aos="flip-up" data-aos-duration="300">
        <h3>About our company</h3>
        <p className="universal-paraghraph">Vysion Technology aims to contribute towards developing India by solving the root problems hindering the development pace. Our quest for strengthening the nation is the driving force behind our Innovations. We aspire to revolutionize digital education and support efficient energy production. We strive to contribute to building superior infrastructure and cleaner, greener, and smarter cities. </p>
        <div className="card_decoration scale">
          <img src={taleri} alt="" />
        </div>
      </div>
      <div className="below">
        <div className="ourServices" data-aos="flip-left" data-aos-duration="300">
          <h3>Our Services</h3>
          <p className="universal-paraghraph">
            We tend to deliver smart solutions in the sectors where the Nation is lagging to compete in the world. We help to build better infrastructure for technology-driven industries.
          </p>
          <div className="card_decoration scale">
            <img src={taleri} alt="" />
          </div>
        </div>
        <div className="OurteamAbout" data-aos="flip-right" data-aos-duration="300">
          <h3>Our team</h3>
          <p className="universal-paraghraph">
            The team comprises of young and skilled enthusiasts from IITs, NITs, and IIITs innovating tirelessly in different domains like IoT, Artificial Intelligence, Mobile and Web Development, Robotics, Embedded Systems, Cloud, and so on.
          </p>
          <div className="card_decoration scale">
            <img src={taleri} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
