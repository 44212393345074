import React from 'react';
import styles from './gurucamp.module.css';

export default function Gurucamp() {
    return (
        <section className='panel'>
            <div className={styles.gurucamp} id='productgurucamp'>
                {/* <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/gurucamp_background_2x_VkCKwAV-t.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648667488035" alt="guru" className={styles.guru}/> */}
                <div className="detailWrapper">
                    <div className="upperDetails">
                        <div className="logoWrapper">
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/Frame%2027342_n4YtECHMu%201_AQvfqvqlO.png?updatedAt=1713518530925" alt="" />
                        </div>
                        <div className="p_name">
                            <h1 className="heading40px">JalSaathi</h1>
                            <p className='universal-paraghraph'>online continuous effluent monitoring system
                            </p>
                        </div>
                    </div>
                    <div className={styles.descriptionWrapper} data-aos="fade-right"><p className='universal-paraghraph'>
                        Jalsaathi ensures water quality compliance, analyzing data and suggests optimized plant measures.</p>
                        <button className='universal-button' style={{ backgroundColor: "#338FEB" }} onClick={()=>{window.open("https://jalsaathi.techvysion.com/" , "_blank")}}>Visit Web Application</button>
                    </div>
                </div>
                <div className={styles.animationWrapper} data-aos="fade-left">
                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/Group%2027238_SdJJnINJp.png?updatedAt=1713516958719" alt="gg" className={styles.guruFeatures} />
                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/mmiio27342_k2FlNtcmV.png?updatedAt=1713518381279" alt="device" className={styles.guruDevice} />
                </div>
            </div>
        </section>
    )
}
