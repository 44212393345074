import React, { useState } from 'react';
import navbarStyles from './navbar.module.css'
import logoMobile from '../assets/logoMobile.png';
import logoR from '../assets/VysionTrademarkR navBarLogo 150x50.png';

const Navbar = (    ) => {
    const [Active, setActive] = useState(0);

    // var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {

        // for hiding the navbar on scroll down.
        var currentScrollPos = window.pageYOffset;
        // if (prevScrollpos > currentScrollPos) {
        //     document.getElementById("navbar").style.top = "0";
        // } else {
        //     document.getElementById("navbar").style.top = "-40px";
        // }
        // console.log(document.getElementById('homeo').offsetTop);
        // prevScrollpos = currentScrollPos;


        // for changing the navbar color on scroll
        // if (currentScrollPos < document.getElementById('about').offsetTop) {
        //     document.getElementById("navbar").style.backgroundColor = "transparent";
        // }
        // else if (currentScrollPos < document.getElementById('products').offsetTop) {
        //     document.getElementById("navbar").style.backgroundColor = "#184281b9";
        // }
        // else if (currentScrollPos < document.getElementById('productyuvaan').offsetTop - 100) {
        //     document.getElementById("navbar").style.backgroundColor = "#155881b9";
        // }
        // else if (currentScrollPos < document.getElementById('productgurucamp').offsetTop) {
        //     document.getElementById("navbar").style.backgroundColor = "#0c9136b9";
        // }
        // else if (currentScrollPos < document.getElementById('Team').offsetTop) {
        //     document.getElementById("navbar").style.backgroundColor = "#f36c24b9";
        // }
        // else {
        //     document.getElementById("navbar").style.backgroundColor = "#184281b9";
        // }

        if (currentScrollPos >= document.getElementById("homeo").offsetTop && currentScrollPos < document.getElementById("homeo").offsetHeight + document.getElementById("homeo").offsetTop) {
            setActive(0);
        }

        else if (currentScrollPos >= document.getElementById("about").offsetTop && currentScrollPos < document.getElementById("about").offsetHeight + document.getElementById("about").offsetTop) {
            setActive(1);
        }

        else if (currentScrollPos >= document.getElementById("products").offsetTop && currentScrollPos < document.getElementById("products").offsetHeight + document.getElementById("products").offsetTop) {
            setActive(2);
        }

        else if (currentScrollPos >= document.getElementById("Team").offsetTop && currentScrollPos < document.getElementById("Team").offsetHeight + document.getElementById("Team").offsetTop) {
            setActive(3);
        }
    }


    const [hamMenu, sethamMenu] = useState(false);
    
    // autoscroll function for mobile devices
    const scrollToSection = (key) => {
        window.scrollTo({
            top: document.getElementById(key).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className={navbarStyles.navbarBase} id='navbar'>
            <div className={navbarStyles.navbarWrapper}>
                <div className={navbarStyles.companylogocontainer} onClick={() => { scrollToSection("homeo") }}>
                    <img src={logoR} alt="logo" id={navbarStyles.logoDesktop} />
                    <img src={logoMobile} alt="" id={navbarStyles.logoMobile} />
                </div>
                <div className={navbarStyles.websiteLinks} id='he'>
                    <a href="#homeo" className={Active === 0 ? "linkContainer active" : "linkContainer"}
                    >Home</a>
                    <a href="#about" className={Active === 1 ? "linkContainer active" : "linkContainer"}
                    >About</a>
                    <a href="#products" className={Active === 2 ? "linkContainer active" : "linkContainer"}
                    >Products</a>
                    <a href="#Team" className={Active === 3 ? "linkContainer active" : "linkContainer"}
                    >Contact</a>
                </div>



                <div className={navbarStyles.hamburgerMenu} id='ham'>
                    <div className={navbarStyles.bars} onClick={() => { sethamMenu(true) }}>
                        <div className={navbarStyles.bar}></div>
                        <div className={navbarStyles.bar}></div>
                        <div className={navbarStyles.bar}></div>
                    </div>
                    {
                        hamMenu === true ? <div className={navbarStyles.hamMenuItems}>
                            <div onClick={() => { sethamMenu(false) }} data-aos="fade-up" data-aos-duration = "100">
                                <i className="fas fa-times"></i>
                            </div>
                            <a onClick={() => { sethamMenu(false) }} href="#homeo" className={Active === 0 ? "option active" : "option"} data-aos="fade-up" data-aos-duration="200">Home</a>
                            <a onClick={() => { sethamMenu(false) }} href="#about" className={Active === 1 ? "option active" : "option"} data-aos="fade-up" data-aos-duration="400">About</a>
                            <a onClick={() => { sethamMenu(false) }} href="#products" className={Active === 2 ? "option active" : "option"} data-aos="fade-up" data-aos-duration="600">Products</a>
                            {/* <a onClick={() => { sethamMenu(false) }} href="#career" className={navbarStyles.option}>Career</a> */}
                            <a onClick={() => { sethamMenu(false) }} href="#Team" className={Active === 3 ? "option active" : "option"} data-aos="fade-up" data-aos-duration="800">Team</a>
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar;