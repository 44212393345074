import React, { useEffect } from 'react'

export default function Privacy() {
    useEffect(() => {
        window.open("https://drive.google.com/file/d/1AsBi3W4BSD0cjlpYktOdx-BysqYWte8a/view?usp=sharing")
    }, [])

    return (
        <div>Privacy Policy will open automatically. (Make sure you allowed popups for this site. If not, allow popups and try again.)</div>
    )
}
