import React from 'react';
import styles from './yuvaan.module.css';
// import gsap from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function Yuvaan() {

    return (
        <section className='panel'>
            <div className={styles.yuvaan} id='productyuvaan'>
                <div className={styles.backbhai}>
                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/decorationyuvaan_VfNXC9cLp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648664095632" alt="yuv" className={styles.mountains} />
                    <div className="detailWrapper">
                        <div className="upperDetails">
                            <div className="logoWrapper">
                                <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/yuvaanlogo_7oWHxf19B.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648664097005" alt="" />
                            </div>
                            <div className="p_name">
                                <h1 className="heading40px">Dhruvaan</h1>
                                <p className='universal-paraghraph'>Greener, Cleaner and the most sustainable
                                </p>
                            </div>
                        </div>
                        <div className={styles.descriptionWrapper} data-aos = "fade-left"><p className='universal-paraghraph'>
                            <b style={{ color: 'var(--yuvaanDarkGreen)' }}>Dhruvaan</b> is a smart and robust robot connected with the potential of IoT and the Cloud. The robot enables faster and efficient cleaning of commercial Solar Plant Panels at a lower operational cost by reducing water consumption and labor. </p>
                            <a href="https://dhruvaan.com/" target="_blank" rel="noreferrer"><button className='universal-button yuvaan'>Visit Website</button></a>
                        </div>
                    </div>
                    <div className={styles.animationWrapper} data-aos = "fade-right">
                        <div className={styles.center}>
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/yuvFeatures_yVqgWOZcs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648833948640" alt="yuvaanFeatures" className={styles.yuvaanFeatures} />
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Frame_8721_LS-PoLWiL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675669676446" alt="yuvaandevice" className={styles.yuvaanDevice} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
