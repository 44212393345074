import React, { useEffect } from 'react'

export default function ContactUs() {
    useEffect(() => {
        window.open("https://drive.google.com/file/d/1jJHd0jzhTsusnMVsKliZPxZfG53ne4Bt/view?usp=sharing")
    }, [])

    return (
        <div>Contact Document will open automatically. (Make sure you allowed popups for this site. If not, allow popups and try again.)</div>
    )
}
