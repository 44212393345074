import React from 'react';
import { useState, useEffect } from 'react';
import styles from './contact.module.css';
import emailjs from '@emailjs/browser';
import taleri from '../assets/Taieri.svg';

export default function Contact(props) {
    const [Result, showResult] = useState(0);
    const sendEmail = (e) => {
        e.preventDefault();
        // sending mail to official comapany mail
        emailjs.sendForm('service_qtef6cz', 'template_p24956a', e.target, 'e9IyAI2Jct588igKl')
            //emailjs.sendForm('service_u129yov', 'template_60vr1v2', e.target, 'user_8PpG3ZPT7ffRvVjXahzd4')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
        showResult(1);
    };

    useEffect(() => {
        if (Result === 1) {
            setTimeout(() => {
                showResult(2);
                document.getElementById("sendButton").style.backgroundColor = "green"

            }, 1500);

            setTimeout(() => {
                showResult(0)
                document.getElementById("sendButton").style.backgroundColor = "var(--accentColor)"
            }, 5000);
        }
    }, [Result])

    // const [baseStyle, setbaseStyle] = useState('80px')
    // const [contactFormIs, setcontactFormIs] = useState(false)
    // const expand_contactForm = () => {
    //     if (contactFormIs === false) {
    //         setbaseStyle('400px')
    //         setcontactFormIs(true)
    //     }
    //     else {
    //         setbaseStyle('80px')
    //         setcontactFormIs(false)
    //     }
    // }



    return (

        <div className={styles.contactWrapper}>

            <div className={styles.contact} id="contact">

                {/* left section */}
                <div className={styles.base}>
                    <div className={styles.contactHeading} data-aos="fade-down" data-aos-duration="300">
                        <p>Have any Querries? Send us here.</p>
                    </div>
                    <div className={styles.contactForm}>
                        <form className={styles.textFeilds} onSubmit={sendEmail}>
                            <div className={styles.nameEmail} data-aos="fade-up" data-aos-duration="300">
                                <input type="text" name="fullName" id={styles.name} placeholder="What's your name?" required></input>
                                <input type="text" name="email" id={styles.email} placeholder="What's your email?" required></input>
                            </div>
                            <div className={styles.message} data-aos="fade-up" data-aos-duration="400">
                                <textarea name="message" id="" placeholder="Write your message here..." required></textarea>
                            </div>
                            <button className={styles.send} id="sendButton" data-aos="fade-up" data-aos-duration="500">
                                {
                                    Result === 1 ? <lord-icon
                                        src="https://cdn.lordicon.com/xjovhxra.json"
                                        trigger="loop"
                                        colors="primary:#ffffff,secondary:#08a88a"
                                        style={{ width: "32px", height: "32px" }}>
                                    </lord-icon>
                                        : Result === 2 ? "Mail sent"
                                            : Result === 0 ? <lord-icon
                                                src="https://cdn.lordicon.com/rhvddzym.json"
                                                trigger="hover"
                                                colors="primary:#ffffff,secondary:#08a88a"
                                                style={{ width: "32px", height: "32px", overflow: "visible", whiteSpace: "nowrap", transform: "translateX(50px)" }}>
                                                <p className={styles.sendPara}>Send Email</p>
                                            </lord-icon>
                                                : "Send Email"
                                }
                            </button>
                            <button className={styles.closeWrapper} data-aos="fade-up" data-aos-duration="600" onClick={() => {
                                props.setContactView(false)
                                props.setCardTwoStyle({
                                    transform: "scale(1)",
                                    backgroundColor: "#121d30"
                                })
                            }}
                            >Close</button>

                        </form>
                    </div>

                </div>
                <div className={styles.otherDetails} data-aos="fade-left" data-aos-duration="300">

                    <button className={styles.closeContactButton} onClick={() => {
                        props.setContactView(false)
                        props.setCardTwoStyle({
                            transform: "scale(1)",
                            backgroundColor: "#121d30"
                        })
                    }}
                    >
                        <lord-icon
                            src="https://cdn.lordicon.com/mecwbjnp.json"
                            trigger="hover"
                            colors="primary:#061021,secondary:#ffffff"
                            style={{ width: "100px", height: "100px" }}>
                        </lord-icon>
                    </button>


                    <div className={styles.vysion} data-aos="fade-left" data-aos-duration="300">
                        <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/LandingVysion_h6b7jHRup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655200955883" alt="vysion" />
                    </div>

                    <div className={styles.visit} data-aos="fade-left" data-aos-duration="400">
                        <p><b>Visit Office</b></p>
                        <p>VYSION GREENBOTS PVT. LTD.<br />
                            170, Ram Nagar, Jhanwar Rd, Katariya Choukha, JODHPUR, Rajasthan, INDIA</p>
                    </div>

                    <div className={styles.emailus} data-aos="fade-left" data-aos-duration="500">
                        <p><b>Contact</b></p>
                        <p>+91 - 80786 93503</p>
                        <p>info@techvysion.com</p>
                    </div>

                    <div className={styles.socials} data-aos="fade-left" data-aos-duration="600">
                        <div className={styles.followUsOn}>
                            <p><b>Follow us on</b></p>
                            <div data-aos="fade-left" data-aos-duration="700">
                                <a href="https://www.instagram.com/vysion_tech" target="_blank" rel="noreferrer"><i className="ri-instagram-line" id={styles.insta}></i></a>

                                <a href="https://twitter.com/vysion_tech" target="_blank" rel="noreferrer"><i className="ri-twitter-fill" id={styles.twitter}></i></a>

                                <a href="https://in.linkedin.com/company/vysion-tech" target="_blank" rel="noreferrer"><i className="ri-linkedin-fill" id={styles.linkedin}></i></a>

                                <a href="https://www.facebook.com/vysiontech95" target="_blank" rel="noreferrer"><i className="ri-facebook-fill" id={styles.facebook}></i></a>
                            </div>
                        </div>

                    </div>

                    <div className={styles.card_decoration}>
                        <img src={taleri} alt="" />
                    </div>

                </div>
            </div>

        </div>
    )
}





