import React, { useState } from 'react';
import mainStyles from './mainbody.module.css';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import decon from '../assets/Decon.png'
import { guruji } from '../assets/assets';
// import gsap from "gsap";

const MainBody = () => {

    const reset = () => {
        document.getElementById('deconDevice').style.display = "none";
        document.getElementById('yuvaanDevice').style.display = "none";
        document.getElementById('gurucoolPhoto').style.display = "none";
        // document.getElementById('vysionLogo').style.display = "none";
        document.getElementById('decon').style.color = "white";
        document.getElementById('yuv').style.color = "white";
        document.getElementById('guru').style.color = "white";
    }


    const particlesInit = async (main) => {
        // console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };


    const options = {
        "fullScreen": {
            "enable": true,
            "zIndex": 1
        },

        "particles": {
            "number": {
                "value": 12,
                "density": {
                    "enable": true,
                    "value_area": 800
                }
            },
            "color": {
                "value": "#ffffff"
            },
            "shape": {
                "type": "circle",
                "stroke": {
                    "width": 0,
                    "color": "#000000"
                },
                "polygon": {
                    "nb_sides": 3
                },
                "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                }
            },
            "opacity": {
                "value": 0.3,
                "random": false,
                "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 2,
                "random": true,
                "anim": {
                    "enable": false,
                    "speed": 0,
                    "size_min": 14.385614385614387,
                    "sync": false
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 150,
                "color": "#ffffff",
                "opacity": 0.2,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 2,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "detect_on": "canvas",
            "events": {
                "onhover": {
                    "enable": true,
                    "mode": "repulse"
                },
                "onclick": {
                    "enable": false,
                    "mode": "push"
                },
                "resize": true
            },
            "modes": {
                "grab": {
                    "distance": 400,
                    "line_linked": {
                        "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                },
                "repulse": {
                    "distance": 200,
                    "duration": 0.4
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
            }
        },
        "retina_detect": true,

        "background": {
            "color": "transparent",
            "image": "",
            "position": "50% 50%",
            "repeat": "no-repeat",
            "size": "cover"
        }
    }

    const [ToolTip, setToolTip] = useState(0);
    let timer;
    const showTip = (id) => {
        timer = setTimeout(() => {
            setToolTip(id)
        }, 3000);
    }
    const hideTip = () => {
        clearTimeout(timer);
        setToolTip(0);
    }

    return (

        <section className='panel'>
            <div id="homeo">

                <div className={mainStyles.particlesStyle}>
                    <Particles
                        id="tsparticles"
                        init={particlesInit}
                        options={options}
                    />
                </div>

                <div className={mainStyles.mainBody}>
                    <div className={mainStyles.contentWrapper}>
                        <div className={mainStyles.text}>

                            <div className={mainStyles.productsWrapper}>
                                <div
                                    className={mainStyles.product}
                                    id="decon"
                                    onMouseEnter={() => {
                                        reset();
                                        document.getElementById('decon').style.color = "#155881";
                                        document.getElementById('deconDevice').style.display = "block";
                                        // document.getElementById('decoration').style.right = "-35vw";
                                        showTip(1);
                                    }}
                                    onMouseLeave={() => {
                                        reset();
                                        // document.getElementById('vysionLogo').style.display = "block";
                                        document.getElementById('decon').style.color = "white";
                                        // document.getElementById('decoration').style.right = "0";
                                        hideTip();
                                    }}
                                >Decon
                                    {
                                        ToolTip === 1 ? <div className={mainStyles.tooltip}>Click to know more</div> : ""
                                    }
                                    <a href="#products"><div
                                        className={mainStyles.onClick}
                                        onMouseEnter={() => {
                                            document.getElementById('poss').style.transform = "translateX(140%)"
                                            document.getElementById('inf').style.transform = "translateX(-140%)"
                                            document.getElementById('paraG').style.opacity = "0"
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById('poss').style.transform = "translateX(0px)"
                                            document.getElementById('inf').style.transform = "translateX(0px)"
                                            document.getElementById('paraG').style.opacity = "1"
                                        }}
                                    ></div></a>
                                </div>

                                <div
                                    className={mainStyles.product}
                                    id="yuv"
                                    onMouseEnter={() => {
                                        reset();
                                        document.getElementById('yuvaanDevice').style.display = "block";
                                        document.getElementById('yuv').style.color = "#46A52C";
                                        // document.getElementById('decoration').style.right = "-35vw";
                                        showTip(2)
                                    }}
                                    onMouseLeave={() => {
                                        reset()
                                        // document.getElementById('vysionLogo').style.display = "block";
                                        document.getElementById('yuv').style.color = "white";
                                        // document.getElementById('decoration').style.right = "0";
                                        hideTip();
                                    }}
                                >Dhruvaan
                                    {
                                        ToolTip === 2 ? <div className={mainStyles.tooltip}>Click to know more</div> : ""
                                    }
                                    <a href="#productyuvaan"><div
                                        className={mainStyles.onClick}
                                        onMouseEnter={() => {
                                            document.getElementById('poss').style.transform = "translateX(140%)"
                                            document.getElementById('inf').style.transform = "translateX(-140%)"
                                            document.getElementById('paraG').style.opacity = "0"
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById('poss').style.transform = "translateX(0px)"
                                            document.getElementById('inf').style.transform = "translateX(0px)"
                                            document.getElementById('paraG').style.opacity = "1"
                                        }}
                                    ></div></a>
                                </div>

                                <div
                                    className={mainStyles.product}
                                    id="guru"
                                    onMouseEnter={() => {
                                        reset();
                                        document.getElementById('gurucoolPhoto').style.display = "block";
                                        document.getElementById('guru').style.color = "#F36C24";
                                        // document.getElementById('decoration').style.right = "-35vw";
                                        showTip(3);
                                    }}
                                    onMouseLeave={() => {
                                        reset();
                                        document.getElementById('guru').style.color = "white";
                                        // document.getElementById('vysionLogo').style.display = "block";
                                        // document.getElementById('decoration').style.right = "0";
                                        hideTip();
                                    }}
                                >Jalsaathi
                                    {
                                        ToolTip === 3 ? <div className={mainStyles.tooltip}>Click to know more</div> : ""
                                    }
                                    <a href="#productgurucamp"><div
                                        className={mainStyles.onClick}
                                        onMouseEnter={() => {
                                            document.getElementById('poss').style.transform = "translateX(140%)"
                                            document.getElementById('inf').style.transform = "translateX(-140%)"
                                            document.getElementById('paraG').style.opacity = "0"
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById('poss').style.transform = "translateX(0px)"
                                            document.getElementById('inf').style.transform = "translateX(0px)"
                                            document.getElementById('paraG').style.opacity = "1"
                                        }}
                                    ></div></a>
                                </div>
                            </div>

                            <div className={mainStyles.textContents}>
                                <h1 className='heading60px' id="poss"><span style={{ fontWeight: "100" }}>The</span> Possibilities</h1>
                                <h1 className='heading60px' id="inf"><span style={{ fontWeight: "100" }}>of</span> Infinity</h1>
                                <p className="universal-paraghraph al" id='paraG'>Strengthening the World with Innovative Solutions</p>

                                <div className={mainStyles.image}>

                                    <img src={decon} className={mainStyles.img} alt="l" id='deconDevice' />

                                    <img src="https://ik.imagekit.io/hgfugmm0dbi/Frame_8721_LS-PoLWiL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675669676446" className={mainStyles.img} alt="l" id='yuvaanDevice' />

                                    <img src={guruji} className={mainStyles.img} alt="l" id='gurucoolPhoto' />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainBody;
