import React from 'react';
import styles from './footer.module.css';
import logoR from '../assets/VysionTrademarkR 725x242.png';


export default function Footer() {
  return (
    <section className='panel'>
      <div className={styles.footer}>
        <div className={styles.upperFooter}>

          <div className={styles.vysion}>
            <img src={logoR} alt="vysion" />
          </div>

          <div className={styles.explore}>
            <p><b>Explore</b></p>
            <a href="#homeo"><p>VYSION GREENBOTS PVT. LTD.</p></a>
            <a href="https://decon.techvysion.com/" target="_blank" rel="noreferrer"><p>Decon</p></a>
            <a href="https://dhruvaan.com/" target="_blank" rel="noreferrer"><p>Dhruvaan</p></a>
            <p style={{ cursor: 'not-allowed' }}>Gurucamp</p>
          </div>

          <div className={styles.visit}>
            <p><b>Visit</b></p>
            <p>VYSION GREENBOTS PVT. LTD. 
            170, Ram Nagar, Jhanwar Rd, Katariya Choukha, JODHPUR, Rajasthan, INDIA
              </p>
          </div>

          <div className={styles.emailus}>
            <p><b>Contact Us</b></p>
            <p>+91 - 80786 93503</p>
            <p>info@techvysion.com</p>
          </div>

          <div className={styles.legal}>
            <p><b>Legal</b></p>
            <p>Terms</p>
            <p>Privacy</p>
          </div>
        </div>
        <div className={styles.lowerFooter}>
          <div className={styles.socials}>

            <a href="https://www.instagram.com/vysion_tech" target="_blank" rel="noreferrer"><i className="ri-instagram-line" id={styles.insta}></i></a>

            <a href="https://twitter.com/vysion_tech" target="_blank" rel="noreferrer"><i className="ri-twitter-fill" id={styles.twitter}></i></a>

            <a href="https://in.linkedin.com/company/vysion-tech" target="_blank" rel="noreferrer"><i className="ri-linkedin-fill" id={styles.linkedin}></i></a>

            <a href="https://www.facebook.com/vysiontech95" target="_blank" rel="noreferrer"><i className="ri-facebook-fill" id={styles.facebook}></i></a>

          </div>
          <p style={{fontSize : "12px"}}>@ 2024 VYSION GREENBOTS PRIVATE LIMITED. All right reserved.</p>
        </div>
      </div>
    </section>
  )
}
