import './App.css';
import Navbar from './components/navbar';
import MainBody from './components/mainbody';
import About from './components/about';
import Decon from './components/Decon';
import RoadVision from './components/RoadVision';
import Yuvaan from './components/Yuvaan';
import Gurucamp from './components/Gurucamp';
import Footer from './components/Footer';
// import Contact from './components/Contact';
import Team from './components/Team';
import { useState, useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Route, Routes } from 'react-router';
import ContactUs from './components/ContactUs';
import Terms from './components/Terms';
import Cancel from './components/Cancel';
import Privacy from './components/Privacy';

function App() {
  const [preloader, setpreloader] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (preloader === true) {
        // setpreloader(false)
        document.getElementById('loader').style.opacity = "0";
      }
    }, 5000);
  });

  setTimeout(() => {
    setpreloader(false)
  }, 5200);

  return (
    <>
      {preloader === true ? <div id='loader'>  <Loading /></div> : ""}

      <Routes>
        <Route path='' element={<WebsiteLayout />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/termsconditions' element={<Terms />} />
        <Route path='/cancellationpolicy' element={<Cancel />} />
        <Route path='/privacypolicy' element={<Privacy />} />
      </Routes>

    </>
  );
}

export default App;

function Loading() {
  return (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 3061.4 3061.4" style={{ enableBackground: "new 0 0 3061.4 3061.4" }} xmlxspace="preserve" className='loadinghehe'>

      {/* <rect className="st0 svg-elem-1" width="3061.4" height="3061.4"></rect> */}
      <g>
        <g>
          <rect x="506.9" y="1255.9" className="st1 svg-elem-2" width="117.9" height="20.4"></rect>

          <rect x="456.8" y="1452.2" transform="matrix(0.2487 0.9686 -0.9686 0.2487 1917.7776 452.356)" className="st1 svg-elem-3" width="421" height="20.4"></rect>
          <polygon className="st1 svg-elem-4" points="655.9,1653 642.2,1681.1 642.1,1681.1 634.3,1650.8 536.5,1269.8 556.2,1264.7 648,1622.5 		"></polygon>
          <polygon className="st1 svg-elem-5" points="845.1,1263.6 655.9,1653 642.2,1681.1 642.1,1681.1 634.3,1650.8 648,1622.5 826.8,1254.7 		"></polygon>
          <rect x="826.9" y="1254.5" className="st1 svg-elem-6" width="116" height="20.4"></rect>

          <rect x="820" y="1260.1" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -386.3176 2154.0698)" className="st1 svg-elem-7" width="127.7" height="20.1"></rect>

          <rect x="468.6" y="1580" transform="matrix(-0.4372 0.8994 -0.8994 -0.4372 2519.8184 1603.5299)" className="st1 svg-elem-8" width="579.2" height="20.4"></rect>
        </g>
        <g>
          <g>
            <path className="st2 svg-elem-9" d="M1063,1666.6h-27.7v-9.9h67.5v9.9h-27.9v79.9H1063V1666.6z"></path>
            <path className="st2 svg-elem-10" d="M1232.6,1704.4h-35.5v32.4h39.5v9.7h-51.3v-89.8h49.3v9.7h-37.5v28.4h35.5V1704.4z"></path>
            <path className="st2 svg-elem-11" d="M1388,1743.6c-4.3,2.1-13,4.3-24.1,4.3c-25.7,0-45.1-16-45.1-45.4c0-28.1,19.4-47.2,47.6-47.2
				c11.4,0,18.5,2.4,21.7,4l-2.8,9.5c-4.5-2.1-10.8-3.7-18.4-3.7c-21.4,0-35.6,13.5-35.6,37c0,22,12.9,36.1,35,36.1
				c7.2,0,14.5-1.5,19.2-3.7L1388,1743.6z"></path>
            <path className="st2 svg-elem-12" d="M1487,1656.7v37.6h44.1v-37.6h11.9v89.8h-11.9v-42.1H1487v42.1h-11.8v-89.8H1487z"></path>
            <path className="st2 svg-elem-13" d="M1635.8,1746.5v-89.8h12.9l29.2,45.4c6.8,10.5,12,20,16.4,29.2l0.3-0.1c-1.1-12-1.4-22.9-1.4-36.9v-37.6
				h11.1v89.8h-11.9l-29-45.6c-6.4-10-12.4-20.3-17-30l-0.4,0.1c0.7,11.3,0.9,22.1,0.9,37v38.4H1635.8z"></path>
            <path className="st2 svg-elem-14" d="M1875.2,1700.7c0,30.9-19.1,47.3-42.4,47.3c-24.1,0-41-18.4-41-45.6c0-28.5,18-47.2,42.4-47.2
				C1859.1,1655.3,1875.2,1674.1,1875.2,1700.7z M1804.4,1702.2c0,19.2,10.6,36.4,29.1,36.4c18.7,0,29.2-16.9,29.2-37.3
				c0-17.9-9.5-36.5-29.1-36.5C1814.1,1664.7,1804.4,1682.5,1804.4,1702.2z"></path>
            <path className="st2 svg-elem-15" d="M1962.8,1656.7h11.8v80.1h39v9.7h-50.7V1656.7z"></path>
            <path className="st2 svg-elem-16" d="M2171.8,1700.7c0,30.9-19.1,47.3-42.4,47.3c-24.1,0-41-18.4-41-45.6c0-28.5,18-47.2,42.4-47.2
				C2155.7,1655.3,2171.8,1674.1,2171.8,1700.7z M2101,1702.2c0,19.2,10.6,36.4,29.1,36.4c18.7,0,29.2-16.9,29.2-37.3
				c0-17.9-9.5-36.5-29.1-36.5C2110.7,1664.7,2101,1682.5,2101,1702.2z"></path>
            <path className="st2 svg-elem-17" d="M2328.9,1742.5c-5.3,1.9-15.7,4.9-28,4.9c-13.8,0-25.2-3.5-34.1-11.9c-7.8-7.5-12.7-19.5-12.7-33.4
				c0.1-26.8,18.8-46.4,49.4-46.4c10.6,0,18.8,2.3,22.7,4.1l-2.8,9.5c-4.9-2.1-11-3.9-20.2-3.9c-22.2,0-36.7,13.6-36.7,36.1
				c0,22.8,13.9,36.2,35.2,36.2c7.7,0,13-1.1,15.7-2.4v-26.8h-18.5v-9.3h30V1742.5z"></path>
            <path className="st2 svg-elem-18" d="M2439.5,1746.5v-38.1l-28.8-51.7h13.4l12.9,24.8c3.5,6.8,6.2,12.3,9.1,18.5h0.3c2.6-5.9,5.7-11.7,9.2-18.5
				l13.1-24.8h13.4l-30.6,51.6v38.2H2439.5z"></path>
          </g>
          <g>
            <path className="st3 svg-elem-19" d="M2049.2,1340.2c-67.2,0-121.7,54.3-121.7,121.2s54.5,121.2,121.7,121.2c67.2,0,121.7-54.3,121.7-121.2
				S2116.5,1340.2,2049.2,1340.2z M2049.2,1547c-46.5,0-84.1-37.5-84.1-83.8s37.7-83.8,84.1-83.8s84.1,37.5,84.1,83.8
				S2095.7,1547,2049.2,1547z"></path>
            <g>
              <path className="st4 svg-elem-20" d="M1126.3,1581l-86.1-241.3h36.8l41.1,118.9c11.4,32.6,21.1,61.9,28.2,90.2h0.8c7.4-27.9,18.4-58.4,30.1-89.9
					l44.6-119.2h36.4l-94.3,241.3H1126.3z"></path>
            </g>
            <g>
              <polygon className="st1 svg-elem-21" points="1433.9,1484.2 1433.2,1485.5 1406.3,1532 1393.4,1554.3 1375.1,1585.9 1374.6,1586.8 
					1322.7,1586.8 1322.5,1586.7 1334.6,1565.7 1382.8,1482.5 1409.6,1436.1 1410.4,1434.8 1432.4,1481.1 				"></polygon>
              <polygon className="st1 svg-elem-22" points="1519.9,1335.7 1506.2,1359.4 1491.6,1384.6 1468.3,1335.7 1519.8,1335.7 				"></polygon>
              <polygon className="st1 svg-elem-23" points="1433.9,1484.2 1433.2,1485.5 1406.3,1532 1405.9,1531 1382.8,1482.5 1312.9,1335.7 1312.9,1335.7 
					1363.2,1335.7 1363.4,1336.2 1410.4,1434.8 1432.4,1481.1 				"></polygon>
              <path className="st1 svg-elem-24" d="M1520.1,1335.7l-25,43.3l-12.9,22.3l-6.5,11.3c-7.8-16.6-15.6-33.1-23.4-49.7c-4.2-9-8.5-18-12.7-27
					c9.5,0,18.9-0.1,28.4-0.1c0.1,0,0.3,0,0.4,0c5.3,0,10.5,0,15.8,0H1520.1z"></path>
            </g>
            <g>
              <path className="st4 svg-elem-25" d="M1579.4,1541c15.3,8.3,37.6,15.2,61.1,15.2c34.8,0,55.2-16.2,55.2-39.7c0-21.7-14.1-34.2-49.7-46.2
					c-43.1-13.5-69.7-33.1-69.7-65.9c0-36.2,34.1-63.1,85.3-63.1c27,0,46.6,5.5,58.3,11.4l-9.4,24.5c-8.6-4.1-26.2-11-50.1-11
					c-36,0-49.7,19-49.7,34.8c0,21.7,16.1,32.4,52.5,44.9c44.6,15.2,67.3,34.2,67.3,68.3c0,35.9-30.1,66.9-92.4,66.9
					c-25.4,0-53.2-6.6-67.3-14.8L1579.4,1541z"></path>
            </g>
            <g>
              <path className="st4 svg-elem-26" d="M1843.9,1341.3v241.3h-39.6v-241.3H1843.9z"></path>
            </g>
            <g>
              <rect x="2253.3" y="1335.7" className="st1 svg-elem-27" width="54.5" height="250.9"></rect>
              <polygon className="st1 svg-elem-28" points="2486.9,1586.6 2421.4,1586.6 2307.8,1427.9 2261.9,1363.8 2307.3,1335.7 2307.8,1336.4 				"></polygon>
              <path className="st1 svg-elem-29" d="M2486.9,1336.6c-26.4,0-52.8,0-79.2,0c26.2,37.3,52.4,74.5,78.7,111.8
					C2486.5,1411.1,2486.7,1373.9,2486.9,1336.6z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}



function WebsiteLayout() {
  return <div>
    <div className="snapper">
      <div className="home">
        <Navbar />
        <MainBody />
      </div>
    </div>
    <div className='snapper'>
      <About />
    </div>
    <div className='snapper'>
      <Decon />
    </div>
    <div className='snapper'>
      <Yuvaan />
    </div>
    <div className='snapper'>
      <Gurucamp />
    </div>
    <div className='snapper'>
      <RoadVision />
    </div>
    <div className='snapper'>
      <Team />
    </div>
    <div className='snapper'>
      <Footer />
    </div>
  </div>
}