import React, { useEffect } from 'react'

export default function Terms() {
    useEffect(() => {
        window.open("https://drive.google.com/file/d/1dZ5OqVIFYP4ERarYaM85Kbp7Mv6Q-jTW/view?usp=sharing")
    }, [])

    return (
        <div>Terms & Conditions will open automatically. (Make sure you allowed popups for this site. If not, allow popups and try again.)</div>
    )
}
