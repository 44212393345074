import React, { useEffect } from 'react'

export default function Cancel() {
    useEffect(() => {
        window.open("https://drive.google.com/file/d/1RA-0TGq5dVJPDwRLYhCXskC9dq7GIDsp/view?usp=sharing")
    }, [])

    return (
        <div>Cancellation and Refund Policy will open automatically. (Make sure you allowed popups for this site. If not, allow popups and try again.)</div>
    )
}
