import React from 'react';
import './ourteam.css'
import taleri from '../assets/Taieri.svg';
import amit from '../assets/profile_photos/amit.png'
import nikhil from '../assets/profile_photos/nikhil.png'
import mayank from '../assets/profile_photos/mayank.png'
import nishant from '../assets/profile_photos/nishant.png'
import prat from '../assets/profile_photos/prat.png'


export default function OurTeam(props) {
    return (
        <div className="teamWrapper">
            <button className="closeContactButton" onClick={() => {
                props.setTeamView(false)
                props.setCardThreeStyle({
                    transform: "scale(1)",
                    backgroundColor: "#121d30",
                    display: "block"
                })
            }}
            >
                <lord-icon
                    src="https://cdn.lordicon.com/mecwbjnp.json"
                    trigger="hover"
                    colors="primary:#061021,secondary:#ffffff"
                    style={{ width: "100px", height: "100px" }}>
                </lord-icon>
            </button>
            <div className="teamWrapper1">

                <MemberCard
                    image={nikhil}
                    name={"Nikhil Vyas"}
                    linkedinUrl={"https://www.linkedin.com/in/nikhil-vyas-4a1a81148/"}
                    instaUrl={"https://www.instagram.com/nik_vyas_95/"}
                    twitterUrl={"https://twitter.com/Nikhilvyas14"}
                    role={"Founder & CEO"} />

                <MemberCard
                    image={prat}
                    name={"Pratyush Gupta"}
                    linkedinUrl={"https://www.linkedin.com/in/pratyush-gupta-917328192/"}
                    instaUrl={"https://www.instagram.com/pratyushgupta190/"}
                    twitterUrl={"https://twitter.com/Pratyus35377059"}
                    role={"Co-Founder & CTO"} />

                <MemberCard
                    image={mayank}
                    name={"Mayank Arora"}
                    linkedinUrl={"https://www.linkedin.com/in/mayank-arora-b78060131"}
                    instaUrl={"https://www.instagram.com/mayank_arora_29/"}
                    twitterUrl={"https://twitter.com/mayanka58776610"}
                    role={"Electronics Reserch Head"} />

            </div>
            <div className="teamWrapper2">

                <MemberCard
                    image={amit}
                    name={"Amit Verma"}
                    linkedinUrl={"https://www.linkedin.com/in/amit-verma-2a46971b4/"}
                    instaUrl={"https://www.instagram.com/it_is_amit/"}
                    twitterUrl={"https://twitter.com/it_is_amit"}
                    role={"Web Development Head"} />

                <MemberCard
                    image={nishant}
                    linkedinUrl={"https://www.linkedin.com/in/nishant-20/"}
                    instaUrl={"https://www.instagram.com/nishant._oo7/"}
                    twitterUrl={"https://twitter.com/iam__oo7"}
                    name={"Nishant Singh"}
                    role={"Design Head"} />

                {/* <MemberCard />
                <MemberCard />
                <MemberCard /> */}
            </div>

        </div>
    )
}

function MemberCard(props) {
    return (
        <div className="MemberCard" data-aos="zoom-in-up" data-aos-duration="300" >
            <div className="profile_photo_wrapper">
                <div className="profile_photo_border">
                    <div className="profile_photo">
                        <img src={props.image} alt="" />
                    </div>
                </div>
            </div>
            <div className="name_role">
                <div className="name universal-paraghraph">{props.name}</div>
                <div className="role">{props.role}</div>
            </div>
            <div className="social_buttons">
                <i className="ri-linkedin-fill" id='mem_linkedin' onClick={() => { window.open(props.linkedinUrl) }}></i>
                <i className="ri-instagram-line" id='mem_insta' onClick={() => { window.open(props.instaUrl) }}></i>
                <i className="ri-twitter-fill" id='mem_twitter' onClick={() => { window.open(props.twitterUrl) }}></i>
            </div>
            <div className="card_decoration">
                <img src={taleri} alt="" />
            </div>
        </div>
    )
}