import React from 'react';
import styles from './about.module.css';
import mission from '../assets/Target.svg';
import vision from '../assets/eye.svg';
import value from '../assets/value.svg';
// import test from '../test.mp4';
// import { useRef } from 'react';

const About = () => {
    return (
        <section className='panel'>
            <div className="page-section" id="about">
                <div className={styles.container}>

                    <div className={styles.vision} data-aos="fade-up" data-aos-duration = "800">
                        <img className={styles.microAnimations} autoPlay src={vision} alt="a" />
                        <h1 className='heading40px'><span className={styles.light}>Our </span> Vision</h1>
                        <p className='universal-paraghraph'>To innovate in the technology driven industries that demand immediate attention & tirelessly strive until we come up with smarter solutions.</p>
                    </div>
                    <div className={styles.vision} data-aos="fade-up" data-aos-duration = "900" >
                        <img className={styles.microAnimations} src={mission} alt="a" />
                        <h1 className='heading40px'><span className={styles.light}>Our  </span> Mission</h1>
                        <p className='universal-paraghraph'>To deliver technical solutions most smartly for complex problems that defy India in becoming the Global Leader.</p>
                    </div>
                    <div className={styles.vision} data-aos="fade-up" data-aos-duration = "1000">
                        <img className={styles.microAnimations} src={value} alt="a" />
                        <h1 className='heading40px'><span className={styles.light}>Our </span> Values</h1>
                        <p className='universal-paraghraph'>To create best high tech products which will help to solve complicated problems and to provide best user experience.</p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;